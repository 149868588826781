import React, { useEffect, useState } from "react";

function useIncrement(number, delay, animate) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (animate) {
      const interval = setInterval(() => {
        setValue((prevValue) => Math.min(prevValue + 1, number));
      }, delay);

      return () => clearInterval(interval);
    }
  }, [number, delay, animate]); 

  return value;
}

function AnimatedCircle({ percentage, animate, description }) {
  const animatedValue = useIncrement(percentage, 20, animate);

  return (
    <section className="flex flex-col items-center w-[35%] max-md:w-full max-md:ml-0">
      {animate && (
        <div className="relative w-[200px] h-[200px] flex-shrink-0 rounded-full flex items-center justify-center">
          <div className="absolute inset-0">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" fill="none" stroke="#D9E1E8" strokeWidth="10" />
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="#282C37"
                strokeWidth="10"
                strokeDasharray="282"
                strokeDashoffset={`${282 - animatedValue * 2.82}`}
              />
            </svg>
          </div>
          <div className="relative z-10">
            <span className="text-lg font-bold" style={{ color: "#548687" }}>
              {animatedValue}%
            </span>
          </div>
        </div>
      )}
      <h2 className="text-taupe text-center text-2xl mt-12 max-md:mt-10 mb-10">{description}</h2>
    </section>
  );
}

export default AnimatedCircle;
