import React from "react";
import Intro from "../component/Intro";
import Percentages from "../component/Percentages";
import ChoosingUs from "../component/ChoosingUs";
import ConnectWithUs from "../component/ConnectWithUs";
import ScrollToTopButton from "../component/ScrollToTopButton";
import MotivatedTeam from "../component/MotivatedTeam";
import Subscribtion from "../component/Subscribtion";

export default function About() {
  return (
    <div className="flex flex-col flex-wrap w-full">
      <Intro />
      <Percentages />
      <ChoosingUs />
      <ConnectWithUs />
      <ScrollToTopButton />
      <MotivatedTeam />
      <Subscribtion />
    </div>
  );
}
