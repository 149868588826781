import React from 'react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen text-white">
      <header className="w-full">
        <Navbar />
      </header>
      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8">
        {children}
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
