import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./component/Layout";
import About from "./views/About";
import Contact from "./views/Contact";
import Service from "./views/Services";

function App() {
  return (
    <Routes >
      <Route
        exact
        path="/"
        element={
          <Layout>
            <About />
          </Layout>
        }
      />
      <Route
        path="/service"
        element={
          <Layout>
            <Service />
          </Layout>
        }
      />
      <Route
        path="/contact"
        element={
          <Layout>
            <Contact />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
