import choosingus from "../assets/images/ap-world-connection.png";

function ChoosingUs() {
  return (
    <div className="flex flex-col px-3">
      <div className="flex flex-col md:flex-row gap-10">
        <section className="w-full md:w-1/2">
          <img loading="lazy" src={choosingus} alt="Why Choosing Us" className="w-full h-auto object-cover" />
        </section>
        <section className="w-full md:w-1/2">
          <h1 className="text-azi-blue mb-8 text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-playfair ">
            Why Choosing us?
          </h1>
          <div className="flex">
            <div className="bg-azi-blue flex w-[30px] h-[100px] flex-col mx-auto "></div>
            <div className="bg-azi-blue w-1 h-full mx-auto"></div>
            <div className="ml-5 ">
              <p className="text-taupe mb-16 font-poppins text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl">
                AziPay Limited is a consulting powerhouse dedicated to steering clients through the complexities of
                project execution with a comprehensive process flow.
              </p>
              <p className="text-taupe mb-16 font-poppins text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl">
                Our team of experts, strategically positioned across Western Europe, ensures personalized, on-the-ground
                engagement at every phase of your project's journey. Our collaborative approach taps into the vast
                reservoir of specialized knowledge, innovative processes, and advanced tools within the AziPay network,
                delivering exceptional value and results.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ChoosingUs;
