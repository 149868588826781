import logo from "../assets/images/AziPay-limited.png";
import building from "../assets/images/ap-buildingfooter.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center p-6 md:p-8 border-t border-gray-300 bg-blue-100">
      <div className="flex flex-col items-start mb-6 md:mb-0 md:w-1/2">
        <NavLink to="/">
          <img loading="lazy" alt="logo" src={logo} className="mb-5 w-full md:w-[80%]" />
        </NavLink>
        <div className="text-azi-blue text-lg md:text-3xl mb-2 pl-4 md:pl-20">
          <p>info@azi-pay.com</p>
          <p>1309 Coffeen Avenue STE 1200 <br />
          Sheridan, WY 82801</p>
        </div>
      </div>
      <img loading="lazy" alt="building" src={building} className="w-full md:w-1/2 max-w-md" />
    </div>
  );
}

export default Footer;
