function MotivatedTeam() {
  return (
    <div className="px-5 py-8">
      <section className="flex flex-col items-center max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row gap-10 lg:gap-20 text-center">
          <h1 className="text-azi-blue text-xl sm:text-2xl md:text-3xl lg:text-4xl font-playfair mb-10 w-full">
            Highly Motivated Team with Innovative Ideas
          </h1>
          <p className="text-taupe text-lg sm:text-xl md:text-2xl lg:text-2xl font-poppins mb-10 w-full">
            Our enthusiasm fuels our quest for creative solutions that promise speed and efficacy. Count on us as your
            steadfast ally in the journey to shared triumph.
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-10">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f39dc864446de6fd634a1df0fc20598ff051f8f79cdbbc8ffb12a6efc8506782?placeholderIfAbsent=true&apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="presentation"
          />
          <div className="bg-azi-blue h-px w-full md:hidden my-5"></div>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a6355a46a2c6d47afc918174ed3fd21b37c6e809edc7eef245bc9c9eb8778b4e?placeholderIfAbsent=true&apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="presenting"
          />
          <div className="bg-azi-blue h-px w-full md:hidden my-5"></div>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f7af3ea167032838cb1c3324c502f19ff85dbc75cce84aa00798bd1cd765fd75?placeholderIfAbsent=true&apiKey=5400aca826c74d1e87d6e429f94c3e3f"
            className="w-full md:w-1/3 h-64 object-cover"
            sizes="(max-width: 640px) 100vw, (min-width: 641px) and (max-width: 768px) 50vw, (min-width: 769px) 33vw"
            alt="observing"
          />
        </div>
      </section>
    </div>
  );
}

export default MotivatedTeam;
