import React, { useState, useEffect, useRef } from "react";
import AnimatedCircle from "./AnimatedCircle";

function Percentages() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);


  return (
    <div className=" flex flex-col px-5" ref={sectionRef}>
      <div className="self-center w-full max-w-[1222px] mt-8 mb-16 max-md:max-w-full max-md:mb-10">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <AnimatedCircle percentage={86} animate={isVisible} description={"Represents the yearly market growth"} />
          <AnimatedCircle percentage={90} animate={isVisible} description={"Represents the market share increment"} />
          <AnimatedCircle percentage={80} animate={isVisible} description={"Represents the customer base growth rate"} />
        </div>
      </div>
    </div>
  );
}
export default Percentages;
