import geo from "../assets/images/ap-building3D.png";

function Intro() {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mt-20 mb-10 w-full">
      <div className="flex flex-col w-full md:w-2/5 px-4 lg:pl-20 lg:pr-10">
        <h1 className="text-azi-blue mb-8 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-playfair">
          Empower Your Business with AziPay Limited
        </h1>
        <p className="text-taupe mb-16 font-poppins text-base sm:text-lg md:text-xl lg:text-xl xl:text-xl">
          AziPay Limited is a consulting company created around a shared desire after several years of experience and
          expertise to offer consulting and support services in various sectors of activity and different levels of
          organizational matrices. Our vision is based on a reinforcement of the strategy of our partners linking their
          identities to the main object of their missions and "core business".
        </p>
      </div>
      <div className="w-full md:w-3/5 lg:w-3/5 flex justify-center md:justify-end px-4">
        <img loading="lazy" alt="geometric-patterns" src={geo} className="w-full h-auto object-contain" />
      </div>
    </div>
  );
}

export default Intro;
