import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/AziPay-limited.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const getActiveClass = (isActive) => (isActive ? "text-azi-blue" : "text-gray-600 hover:text-gray-900");

  return (
    <div className="flex flex-col items-center">
      <header className="bg-blue-100 self-stretch flex w-full pt-0 pb-0 flex-col mt-5 px-5">
        <nav className="self-center z-[1] flex w-full max-w-[1228px] items-center justify-between gap-10 ">
          <NavLink to="/">
            <img
              loading="lazy"
              src={logo}
              className="aspect-[1.42] object-contain object-center w-[400px] overflow-hidden self-stretch max-w-full md:w-[150px] sm:w-[100px]"
              alt="Logo"
            />
          </NavLink>

          <button onClick={() => setIsOpen(!isOpen)} className="text-2xl md:hidden text-black">
            {isOpen ? "✖" : "☰"}
          </button>

          <div
            className={`self-center flex w-full max-w-full items-center justify-center gap-20 my-auto flex-wrap md:items-center ${
              isOpen ? "flex" : "hidden md:flex"
            }`}
          >
            <NavLink
              to="/"
              className={({ isActive }) => `${getActiveClass(isActive)} px-3 py-2 rounded-md md:text-xl sm:text-lg`}
            >
              About
            </NavLink>
            <NavLink
              to="/service"
              className={({ isActive }) => `${getActiveClass(isActive)} px-3 py-2 rounded-md md:text-xl sm:text-lg`}
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => `${getActiveClass(isActive)} px-3 py-2 rounded-md md:text-xl sm:text-lg`}
            >
              Contact
            </NavLink>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
